import React, { useState } from 'react'
import { Slide } from 'react-awesome-reveal'
import { ChangePasswordModal } from './changepassword'
import { NewPasswordModal } from './newpassword'

export const ChangePasswordModalIndex = ({ onClose }) => {

    const [currentLayout, setCurrentLayout] = useState("first")

    const handlePageChange = (page) => {
      setCurrentLayout(() => page)
    }

    const pagesMap = {
        "first" : <ChangePasswordModal onClose={onClose} onPagechange={handlePageChange} />,
        "second" : <NewPasswordModal onClose={onClose} />,
    }

  return (
    <Slide duration={100}>
        { pagesMap[currentLayout] }
    </Slide>
  )
}
import classNames from 'classnames';
import * as React from 'react';
import Reveal, { Bounce, Slide } from 'react-awesome-reveal';
import { createPortal } from 'react-dom';
import * as Icon from 'react-feather'

const modalOverlayBase = classNames(
  'fixed',
  'inset-0',
  'flex',
  'items-center',
  'overflow-hidden',
  'overflow-auto',
  'z-40',
  'bg-tgray-300',
  'bg-opacity-10',
  'backdrop-blur-sm',
  'transition',
  'delay-150',
  'duration-700',
  'ease-in-out',
  'p-4'
);
const modalContentBase = classNames(
  'body-font font-avenir',
  'rounded-xl',
  'relative',
  'w-full',
  'h-full',
  'md:w-2/3',
  'lg:w-1/3',
  'overflow-auto',
  'overflow-y-scroll',
  'max-h-full',
  'max-w-full',
  'no-scrollbar',
  'border-2',
  'border-tgray-100',
);
const positionRight = classNames('justify-end');
const positionLeft = classNames('justify-start');

export const DrawerModal = ({
  show,
  headpadding,
  bodypadding,
  onClose,
  children,
  position,
  headFragment,
  contentWidth,
  contentBgColor,
  footerFragment,
  shouldCloseOnEscPress,
  shouldCloseOnOverlayClick,
}) => {
  const containerId = document.getElementsByTagName('body')[0];

  //when overlay is clicked
  const handleOverlayClick = React.useCallback(() => {
    if (shouldCloseOnOverlayClick && !!onClose) onClose();
  }, [onClose, shouldCloseOnOverlayClick]);

  // on ESC key press
  const closeOnEscapeKeyPress = React.useCallback(
    (e) => {
      if (e.key === 'Escape' && shouldCloseOnEscPress) {
        if (!!onClose) onClose();
      }
    },
    [onClose, shouldCloseOnEscPress]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', closeOnEscapeKeyPress);
    return () => {
      document.addEventListener('keydown', closeOnEscapeKeyPress);
    };
  }, [closeOnEscapeKeyPress]);

  const modalOverlayClass = classNames(modalOverlayBase, {
    [positionLeft]: position === 'left',
    [positionRight]: position === 'right',
  });
  const modalContentClass = classNames(
    modalContentBase,
    contentBgColor,
    contentWidth,
    headpadding,
    bodypadding,
  );

  if (!show) return null;
  return createPortal(
    <div
      role="dialog"
      className={modalOverlayClass}
      onClick={handleOverlayClick}
    >
          <Slide direction={position} duration={400} className={modalContentClass} onClick={(e) => e.stopPropagation()}>
            <div>
              <div
                onClick={onClose}
                style={{
                  background: `rgba(255, 255, 255, 0.50)`,
                  backdropFilter: `blur(16px)`,
                }} 
                className= {`absolute top-0 right-0 block md:hidden mx-3 my-4 p-2.5 cursor-pointer rounded-lg bg-tgray-150`}>
                <Icon.X 
                  color="#000000"
                />
              </div>
              <div className={`w-full ${headpadding}`}>{headFragment}</div>
              <div className={`w-full ${bodypadding}`}>{children}</div>
              <div className="w-full">{footerFragment}</div>
            </div>
          </Slide>
          <div
            onClick={onClose}
            style={{
              background: `rgba(255, 255, 255, 0.50)`,
              backdropFilter: `blur(16px)`,
            }} 
            className= {`absolute top-0 ${ position === 'right' ? 'md:right-2/3 lg:right-1/3 ' : 'md:left-2/3 lg:left-1/3' } hidden md:block mx-3 my-4 p-2.5 cursor-pointer rounded-lg bg-gray-200`}>
            <Icon.X 
              color="white"
            />
          </div>
    </div>,
    containerId
  );
};

DrawerModal.defaultProps = {
//   contentWidth: 'w-1/3',
  position: 'right',
  contentBgColor: 'bg-tprimary-50',
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEscPress: false,
};

import React, { useState } from 'react'
import { CountrySelectorDropdown, FlagImage } from 'react-international-phone'
import { Input } from './input'

export const CustomPhoneInput = ({ enteredTel, telChangeHandler, telBlurHandler, telError, phone, setPhone }) => {

    const [country, setCountry] = useState('ng')
    const [show, setShow] = useState(false)

    const handleSelectDropdown = () => {
        setShow(() => true);
    }
    const handleCloseSelectDropdown = () => {
        setShow(() => false)
    }

    const handleSelectCountry = (item) => {
        setCountry(() => item?.iso2)
        setPhone(() => item?.dialCode)
        handleCloseSelectDropdown()
    }

  return (
    <div className='relative flex items-end justify-center space-x-2 w-full'>
        <div onClick={handleSelectDropdown} className='cursor-pointer flex items-center justify-center space-x-2 py-2.5 pl-3.5 pr-5 rounded-xl border border-tly-stroke'>
            <FlagImage iso2={country} size="20px" />
            <p className='text-xs text-tly-gray'>+{phone}</p>
        </div>
        <CountrySelectorDropdown
            className="w-fit absolute max-h-[200px] rounded-lg p-4 border border-tgray-50 overflow-auto"
            listItemClassName="flex items-center p-2"
            listItemCountryNameClassName="whitespace-nowrap text-sm font-medium pr-2 text-tblack-100"
            listItemDialCodeClassName="text-xs text-tgray-150"
            listItemFlagStyle={{
                width: '20px',
                height: '20px',
                marginRight: '10px'
            }}
            style={{
                position: 'absolute',
                bottom: '0',
                zIndex: 999,
                paddingTop: "10px",
                backgroundColor: '#fff'
            }}
            show={show}
            selectedCountry={country}
            onSelect={handleSelectCountry}
            onClose={handleCloseSelectDropdown}
        />

        <Input
            wrapperClassName = 'w-full'
            name="phone"
            type="tel"
            placeholder="Enter your phone number"
        />
    </div>
  )
}
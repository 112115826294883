import React, { useState } from 'react'
import { Button } from '../../../components/forms/button'
import { Input } from '../../../components/forms/input'
import * as Icon from 'react-feather'

export const NewPasswordModal = ({ onClose }) => {

    const [benchMarks, setBenchMarks] = useState([
        {
            id: 1,
            bench: 'Be a minimum of 8 characters',
            state: false,
        },
        {
            id: 2,
            bench: 'Include at least one lowercase letter (a-z)',
            state: false,
        },
        {
            id: 3,
            bench: 'Include at least one uppercase letter (A-Z)',
            state: false,
        },
        {
            id: 4,
            bench: 'Include at least one number(0-9)',
            state: false,
        },
    ]);

  return (
    <div className='w-full flex flex-col gap-6'>
        <p className='text-xl font-semibold border-b border-tgray-100 w-full p-5'>
            Change Password
        </p>

        <main className='space-y-5 p-5'>
            <p className='text-base text-tgray-150'>
                Please create a new secure password for your account. Make sure it's unique and strong to keep your account safe.
            </p>
            <section className='w-full flex flex-col gap-8'>
                <Input 
                    type="password"
                    name="password"
                    label= "New password"
                    placeholder ="Enter your New password..."
                />
                <Input 
                    type="password"
                    name="password"
                    label= "Confirm password"
                    placeholder ="Enter your New password..."
                />
            </section>
            <section className='w-full flex items-start flex-col gap-4'>
                {
                    benchMarks?.map(item => (
                        <div
                            key={item.id}
                            className='flex items-center gap-4'>
                            <p className={`${ item.state ? 'text-success-100' : 'text-tgray-150' } text-sm `}>{item.bench}</p>
                            { item?.state ? <Icon.CheckCircle className='text-success-100' /> : null }
                        </div>
                    ))
                }
            </section>
        </main>

        <footer className='flex items-center justify-start border-t border-tgray-100 w-full p-5 gap-4'>
            <Button 
                variant='primary'
                children='Save'
            />

            <Button
                variant='link'
                children='Cancel'
                onClick={onClose}
            />
        </footer>
    </div>
  )
}
import { useRef, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { AuditIcon, BarChartIcon, ConnectionIcon, DashIcon, LockIcon, LogOutIcon, PaymentIcon, ProfileIcon, TicketIcon, TLogo, User, UsersIcon } from '../../assets/generated';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { SideBarItem } from '../dashboardlayout/sidebarItem';
import { Avatar } from '../global/avatar';
import { DrawerModal } from '../global/drawer';
import { useOnOutsideClick } from '../../hooks/useOutsideClick';
import { UserDetailCard } from '../global/userdetailcard';
import { Slide } from 'react-awesome-reveal';
import { ProfileModal } from '../../routes/dashboard/profile/profilemodal';
import { Modal } from '../global/modal';
import { ChangePasswordModalIndex } from '../../routes/dashboard/profile/changepasswordindex';
import { useDispatch } from 'react-redux';
import { logOut } from '../../services/authSlice';
import * as Icon from 'react-feather'

const notifications = [
  {
    id: 1,
    category: 'Broadcast',
    title: "Abusive Doctors",
    text: `It has been brought to notice that some doctors are insulting their patients. 
    Some calling them sluts for having STI"s, please watch it!!! or get kicked out`,
    date: 'Sun, 8 Oct 2023'
  },
  {
    id: 2,
    category: 'Broadcast',
    title: "Doctors toasting patient",
    text: `It has been brought to notice that some doctors are sleeping with their patients.
    Please use Protection`,
    date: 'Sun, 8 Oct 2023'
  },
  {
    id: 3,
    category: 'Registration',
    title: "Doctors with fake Licenses",
    text: `We are coming for y'all fake doctors. 
    Watch out!!!`,
    date: 'Sun, 8 Oct 2023'
  },
  
]

export const MainAppLayout = ({ children }) => {

  const dispatch = useDispatch()
  const ref = useRef()
  const [showPanel, setShowPanel] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)


  useOnOutsideClick(ref, () => {
    setShowProfileMenu(() => false);
  });

  const toggleModal = () => {
    setShowDrawer(prev => !prev)
  }

  const toggleProfileModal = () => {
    setShowProfileModal((prev) => !prev)
  }

  const toggleShowPanel = () => {
    setShowPanel((prev) => !prev);
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(prev => !prev)
  }

  const toggleChangePassword = () => {
    setShowChangePasswordModal(prev => !prev)
  }

  const handleLogOut = () => {
    dispatch(
      logOut()
    )
  }

  let isMobile = useMediaQuery("(max-width: 1024px)");
  let isLogoMobile = useMediaQuery("(max-width: 425px)");
  
  return (
    <section className='w-full flex items-center justify-center'>
      <main className='w-full relative h-screen no-scrollbar'>
        {/* Mobile header */}
        <div
          className={`sticky w-full flex items-center justify-between gap-4 border-b border-tgray-100 bg-white z-40 p-4 py-6 h-[7dvh] top-0`}>
            <div className="flex items-center gap-2">
              <TLogo />
              <span className={` ${ !isLogoMobile ? 'block' : 'hidden' } font-bold text-lg sm:text-[26px] text-tblack-100`}>Tendarly</span>
            </div>
            <section className={`relative flex items-center gap-6 text-tgray-150`}>
              <div onClick={toggleModal} className='relative'>
                <Icon.Bell className='cursor-pointer' />
                <span className='cursor-pointer absolute -top-2 -right-1 bg-tprimary-100 flex items-center justify-center p-2 rounded-full w-5 h-5 font-medium text-[10px] text-white'>
                  3
                </span>
              </div>

              <Link to="/settings">
                <Icon.Settings className='cursor-pointer' />
              </Link>
              <div
                ref={ref}
                onClick={toggleProfileMenu} 
                className={`
                  transition-all ease-in-out duration-500 
                  ${ showProfileMenu ? 'border-4 border-[#007BFF33]' : '' } 
                  rounded-full cursor-pointer`
                }
              >
                <Avatar 
                  name="Ogunderu Noah"
                  size='xs'
                />
                <main>
                <Slide
                  direction="right"
                  duration={400}
                  tabIndex={0}
                  className={`
                    ${ showProfileMenu ? 'block' : 'hidden' }
                    transition-all ease-in-out duration-500 
                    absolute text-white top-10 right-0 mt-4 bg-white rounded-lg 
                    overflow-hidden min-w-[15rem] border`
                  }
                >
                  <ProfileCard 
                    onOpenProfile={toggleProfileModal}
                    onOpenChangePassword={toggleChangePassword}
                    logOut={handleLogOut}
                    name="Ogunderu Noah"
                    role="Super Admin"
                    img={""} 
                  />
                </Slide>
              </main>
              </div>
              <Icon.Menu
                className={`${ isMobile ? 'block' : 'hidden' }`}
                width={24}
                height={24}
                color="black"
                onClick={toggleShowPanel}
              />
            </section>
        </div>

        <div className="flex no-scrollbar h-[93dvh]">
          <div
            className={`fixed inset-0 z-30 bg-doc-main lg:hidden`}
            style={{
              opacity: 0.3,
              display: isMobile && showPanel ? "block" : "none",
            }}
            aria-hidden="true"
            onClick={toggleShowPanel}
          ></div>

          <aside
            className={`fixed border-r border-tgray-100 pt-12 pb-2 inset-y-0 z-30 lg:absolute w-60 no-scrollbar bg-white
            ${ isMobile && !showPanel && "hidden"}`}
          >
            <div className="flex flex-col h-full space-y-1 overflow-y-hidden hover:overflow-y-auto">
              <nav className="flex-1 space-y-4 pt-20">
                {/* <IsRole allowedRoles={["user"]} > */}
                  <SideBarItem
                    children="Dashboard"
                    url="dashboard"
                    icon={<DashIcon />}
                    onClick={toggleShowPanel}
                  />
                {/* </IsRole> */}


                {/* <IsRole allowedRoles={["admin"]} > */}
                <SideBarItem
                    children="Users"
                    url="users"
                    icon={<User />}
                    onClick={toggleShowPanel}
                  />
                {/* </IsRole> */}

                {/* <IsRole allowedRoles={["admin"]} > */}
                  <SideBarItem
                    children="Doctors"
                    url="doctors"
                    icon={<UsersIcon />}
                    onClick={toggleShowPanel}
                  />
                {/* </IsRole> */}
                <SideBarItem
                    children="Connections"
                    url="connections"
                    icon={<ConnectionIcon />}
                    onClick={toggleShowPanel}
                  />
                  <SideBarItem
                      children="Payments"
                      url="payments"
                      icon={<PaymentIcon size={18} strokeWidth={1.5} />}
                      onClick={toggleShowPanel}
                  />
                  <SideBarItem
                      children="Reports"
                      url="reports"
                      icon={<BarChartIcon size={18} strokeWidth={1.5} />}
                      onClick={toggleShowPanel}
                  />
                  <SideBarItem
                      children="Tickets"
                      url="tickets"
                      icon={<TicketIcon size={18} strokeWidth={1.5} />}
                      onClick={toggleShowPanel}
                  />
                {/* <IsRole allowedRoles={["admin"]} > */}
                  <SideBarItem
                      children="Broadcast"
                      url="broadcast"
                      icon={<Icon.Radio size={18} strokeWidth={1.5} />}
                      onClick={toggleShowPanel}
                  />
                {/* </IsRole> */}
                  <SideBarItem
                    children="Audit log"
                    url="audit-log"
                    icon={<AuditIcon className='w-12 h-12' />}
                    onClick={toggleShowPanel}
                  />
              </nav>
            </div>
          </aside>

          <main className={`flex-1 w-full ${!isMobile && "pl-60"} `}>
            {/* content */}
            <div className="flex flex-col flex-1 h-full overflow-x-hidden overflow-y-auto no-scrollbar body-font font-Fixel">
              <Outlet />
            </div>
          </main>
        </div>
      </main>

      <DrawerModal 
        show={showDrawer}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEscPress={false}
        onClose={toggleModal}
        headFragment={
          <div className='flex flex-col gap-2 border-b border-tgray-100 p-5'>
            <h1 className='xl font-semibold text-tblack-100'>Notifications</h1>
            <p className='text-base text-tgray-150'>23 Unread</p>
          </div>
        }
      >
        <main className='flex flex-col'>
          {
            notifications.map(item => (
              <NotificationCard 
                key={item.id}
                category={item.category}
                title={item.title}
                text={item.text}
                date={item.date}
              />
            ))
          }
        </main>
      </DrawerModal>

      <Modal
        show={showProfileModal}
        shouldCloseOnEscPress={false}
        shouldCloseOnOverlayClick={false}
        onClose={toggleProfileModal}
        position='center'
        contentWidth='w-full md:w-3/4 xl:w-3/5'
      >
        <ProfileModal onClose={toggleProfileModal} />
      </Modal>

      <Modal
        show={showChangePasswordModal}
        shouldCloseOnEscPress={false}
        shouldCloseOnOverlayClick={false}
        onClose={toggleChangePassword}
        position='center'
        contentWidth='w-full md:w-3/4 xl:w-3/5'
      >
        <ChangePasswordModalIndex onClose={toggleChangePassword} />
      </Modal>
    </section>
  );
};

export const NotificationCard = ({ category, title, text, date }) => {
  return (
    <div className='flex flex-col gap-2 border-b border-tgray-100 p-5'>
      <header className='flex items-center gap-2'>
        <Icon.Circle stroke='none' className="" size={9} fill="#149DD1" />
        <p className='text-base font-medium text-tgray-150'>{category}</p>
      </header>

      <section className='flex flex-col gap-2'>
        <h1 className='text-base text-tblack-100 font-medium'>{title}</h1>
        <p className='text-base text-tblack-100 font-normal'>{text}</p>
        <p className='text-sm text-tgray-150 font-normal'>{date}</p>
      </section>
    </div>
  )
}

const ProfileCard = ({ name, img, role, onOpenProfile, onOpenChangePassword, logOut }) => {
  return(

    <div className='flex items-start flex-col'>
      <div className='w-full flex items-start flex-col gap-3 border-b border-tgray-50 p-3'>
        <p className='text-tblack-100 font-medium text-sm'>Account</p>
        <UserDetailCard
          name={name}
          img={img}
          size="xs"
          node={<span className='text-xs text-tgray-150'>{role}</span>}
        />
      </div>

      <div className='w-full flex flex-col items-start border-b border-tgray-50'>
        <div onClick={onOpenProfile} className='w-full flex items-center gap-2 p-3 hover:bg-slate-100'>
          <ProfileIcon />
          <p className='text-sm text-tblack-100'>View Profile</p>
        </div>

        <div onClick={onOpenChangePassword} className='w-full flex items-center gap-2 p-3 hover:bg-slate-100'>
          <LockIcon />
          <p className='text-sm text-tblack-100'>Change Password</p>
        </div>
      </div>

      <div onClick={logOut} className='flex items-center gap-2 p-3'>
        <LogOutIcon className='rotate-180' color='#F25E43' />
        <p className='text-sm text-[#F25E43] whitespace-nowrap'>Log out</p>
      </div>

    </div>
  )
}
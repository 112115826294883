import React from 'react'
import { Avatar } from './avatar'

export const UserDetailCard = ({ name, img, userId, size='lg', phone, node }) => {
  return (
    <div className='flex items-center gap-3'>
      <Avatar 
        name={name}
        src={img}
        size={size}
      />
      <div className='whitespace-nowrap flex items-start flex-col'>
        <p className='text-base font-medium text-tblack-100'>{name}</p>
        { userId ? <p className='text-sm font-normal text-tgray-150'>ID: {userId}</p> : null }
        { phone ? <p className='text-sm font-normal text-tgray-150'>{phone}</p> : null }
        { node ? <p className='text-sm font-normal text-tgray-150'>{node}</p> : null }
      </div>
    </div>
  )
}
import React, { useState } from 'react'
import { Button } from '../../../components/forms/button'
import { Input } from '../../../components/forms/input'

export const ChangePasswordModal = ({ onClose, onPagechange }) => {

  return (
    <div className='w-full flex flex-col gap-6'>
        <p className='text-xl font-semibold border-b border-tgray-100 w-full p-5'>
            Change Password
        </p>

        <main className='space-y-8 p-5'>

            <p className='text-base text-tgray-150'>
                <span className='font-semibold text-tblack-100'>Old Password Required</span> - Please enter your current (old) password to proceed with changing your account password.
            </p>
            <section className='w-full'>
                <Input 
                    type="password"
                    name="password"
                    label= "Current password"
                    placeholder ="Enter your Current password..."
                />
        </section>
        </main>

        <footer className='flex items-center justify-start border-t border-tgray-100 w-full p-5 gap-4'>
            <Button 
                variant='primary'
                children='Continue'
                onClick={() => onPagechange('second')}
            />

            <Button
                variant='link'
                children='Cancel'
                onClick={onClose}
            />
        </footer>
    </div>
  )
}
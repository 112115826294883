import React, { useState } from 'react'
import { Button } from '../../../components/forms/button'
import { CustomPhoneInput } from '../../../components/forms/customphoneinput'
import { Input } from '../../../components/forms/input'
import { Avatar } from '../../../components/global/avatar'
import * as Icon from 'react-feather'

export const ProfileModal = ({ onClose }) => {

    const [phone, setPhone] = useState('234')
    // const Telephone = `+${phone} ${enteredTel}`
    const [profileImage, setProfileImage] = useState(null);

    const handleFileUpload = (event) => {
        event.preventDefault()
        const { files } = event.target;
        if(!files[0]) return;
        setProfileImage(() => URL.createObjectURL(files[0]))
    }

  return (
    <div className='w-full flex flex-col gap-6'>
        <p className='text-xl font-semibold border-b border-tgray-100 w-full p-5'>
            Profile
        </p>

        <main className='space-y-8 p-5'>
            <section className='w-full flex items-center justify-center gap-4 flex-col'>
                <Avatar 
                    size="2xl"
                    name="Ogunderu Noah"
                    src={profileImage}
                />
                <label>
                    <Input 
                        className='hidden'
                        type='file'
                        name="img"
                        id="img"
                        accept='image/*'
                        onChange={handleFileUpload}
                    />
                    <span className='flex items-center text-sm text-tgray-150'>Change profile Picture<Icon.Image style={{paddingLeft: '2px'}} /></span>
                </label>
            </section>
            <Input
                name="Name"
                label="Name"
                type="text"
                placeholder="Enter your name"
            />

            <section className='w-full grid grid-cols-2 gap-4'>
                <Input 
                    type="email"
                    name="email"
                    label= "Email"
                    placeholder ="Enter your email..."
                />
                <div className='flex items-start flex-col w-full'>
                    <label>Phone Number</label>
                    <CustomPhoneInput
                        phone={phone}
                        setPhone={setPhone}
                        // enteredTel={enteredTel}
                        // telBlurHandler={telBlurHandler}
                        // telChangeHandler={telChangeHandler}
                        // telError={telError}
                    />
                </div>
            </section>
        </main>

        <footer className='flex items-center justify-start border-t border-tgray-100 w-full p-5 gap-4'>
            <Button 
                variant='primary'
                children='Save changes'
            />

            <Button
                variant='link'
                children='Cancel'
                onClick={onClose}
            />
        </footer>
    </div>
  )
}
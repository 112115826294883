import React, { useMemo, useState } from 'react'
import { getNameInitials } from '../../helpers/getInitials';
import classNames from 'classnames'

export const Avatar = ({ name, src, maxInitials=2, size }) => {

    const [hasError, setHasError] = useState(false);

    const baseClass = classNames('flex items-center justify-center rounded-full border borde-[#e6e6e6]')
    const xtrasmallClass = classNames('w-8 h-8')
    const smallClass = classNames('w-12 h-12')
    const mediumClass = classNames('w-16 h-16')
    const largeClass = classNames('w-20 h-20')
    const exLargeClass = classNames('w-24 h-24')
    const xxLargeClass = classNames('w-32 h-32')

    const sizeMap = {
        "xs": xtrasmallClass,
        "sm": smallClass,
        "md": mediumClass,
        "lg": largeClass,
        "xl": exLargeClass,
        "2xl": xxLargeClass,
    }

    const getAvatar = useMemo(() => {
        const hasImageSrc = !!src && src?.length;

        if ((!hasImageSrc && name) || hasError)
        return (
            <div 
                className={classNames(
                    baseClass, sizeMap[size] ?? largeClass, 
                    `text-${size} text-tblack-100  ${ size === '2xl' ? 'font-bold text-4xl' : 'font-semibold' } 
                    bg-tgray-100`
                )}
            >
                { getNameInitials({ name, maxInitials }) }
            </div>
        );

        if (hasImageSrc && !hasError)
        return (
            <div className={classNames(baseClass, sizeMap[size] ?? largeClass)}>
                <img
                    loading='lazy'
                    className={classNames(baseClass, sizeMap[size] ?? largeClass)}
                    style={{
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        objectFit: "cover",
                    }}
                    src={src}
                    alt={name}
                    onError={(event) => setHasError(true)}
                />
            </div>
        );

        if (hasError) return null;
        return null;

    },[src, size, name, hasError, maxInitials])

    return getAvatar;
}
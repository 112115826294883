import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom"
import { MainAppLayout } from './components/layout/mainApp';
import { UsersIndex } from './routes/dashboard/users/index'
import { BroadcastIndex } from './routes/dashboard/broadcast';
import { DoctorIndex } from './routes/dashboard/doctors';
import { EarningIndex } from './routes/dashboard/doctors/earningdetails';
import { AuditIndex } from './routes/dashboard/audit';
import { ConnectionIndex } from './routes/dashboard/connections';
import { PaymentIndex } from './routes/dashboard/payments';
import { Provider } from 'react-redux';
import { store } from './app/store';
import Protected from './utils/protected';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/image.min.js'
import { Toaster } from 'sonner';
import ErrorPage from './routes/error/error';

const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      { 
        index: true,
        loader: () => redirect('sign-in'),
      },
      { 
        path: 'sign-in',
        async lazy() {
          let { SignIn } = await import("./routes/auth/signin/signin");
          return { Component: SignIn };
        },
      },
      { 
        path: 'forgot-password',
        async lazy() {
          let { ForgotPassword } = await import("./routes/auth/forgotpassword/forgotpassword");
          return { Component: ForgotPassword };
        },
      },
      { 
        path: 'create-password/:verifyId',
        async lazy() {
          let { CreateNewPassword } = await import("./routes/auth/createnewpassword/createnewpassword");
          return { Component: CreateNewPassword };
        },
      },
    ]
  },
  {
    element:
      <Protected>
        <MainAppLayout />
      </Protected>,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: 'dashboard',
        async lazy() {
          let { Analytics } = await import("./routes/dashboard/analytics/analytics");
          return { Component: Analytics };
        },
      },
      {
        path: 'users',
        element: <UsersIndex />,
        children: [
          {
            index: true,
            async lazy() {
              let { Users } = await import("./routes/dashboard/users/users");
              return { Component: Users };
            },
          },
          {
            path: 'blocked',
            async lazy() {
              let { BlockedUsers } = await import("./routes/dashboard/users/blockedusers");
              return { Component: BlockedUsers };
            },
          },
          {
            path: ':userId',
            async lazy() {
              let { UserDetail } = await import("./routes/dashboard/users/userdetail/userdetail");
              return { Component: UserDetail };
            },
            children: [
              {
                index: true,
                loader: () => redirect('all'),
              },
              {
                path: 'all',
                async lazy() {
                  let { AllConnects } = await import("./routes/dashboard/users/userdetail/connections/allconnects");
                  return { Component: AllConnects };
                },
              },
              {
                path: 'active',
                async lazy() {
                  let { ActiveConnects } = await import("./routes/dashboard/users/userdetail/connections/activeconnects");
                  return { Component: ActiveConnects };
                },
              },
              {
                path: 'cancelled',
                async lazy() {
                  let { CancelledConnects } = await import("./routes/dashboard/users/userdetail/connections/cancelledconnects");
                  return { Component: CancelledConnects };
                },
              },
              {
                path: 'completed',
                async lazy() {
                  let { CompletedConnects } = await import("./routes/dashboard/users/userdetail/connections/completedconnects");
                  return { Component: CompletedConnects };
                },
              },
            ]
          },
        ]
      },
      {
        path: 'doctors',
        element: <DoctorIndex />,
        children: [
          {
            index: true,
            async lazy() {
              let { Doctors } = await import("./routes/dashboard/doctors/doctors");
              return { Component: Doctors };
            },
          },
          {
            path: 'blocked',
            async lazy() {
              let { BlockedDoctors } = await import("./routes/dashboard/doctors/blockeddoctors");
              return { Component: BlockedDoctors };
            },
          },
          {
            path: 'verifications',
            async lazy() {
              let { VerificationRequest } = await import("./routes/dashboard/doctors/verificationrequests/verification");
              return { Component: VerificationRequest };
            },
          },
          {
            path: 'verifications/log',
            async lazy() {
              let { RequestsLog } = await import("./routes/dashboard/doctors/verificationrequests/log/log");
              return { Component: RequestsLog };
            },
          },
          {
            path: 'verifications/:requestId',
            async lazy() {
              let { RequestDetails } = await import("./routes/dashboard/doctors/verificationrequests/requestdetails");
              return { Component: RequestDetails };
            },
          },
          {
            path: ':doctorId',
            async lazy() {
              let { DoctorDetails } = await import("./routes/dashboard/doctors/doctordetails/doctordetails");
              return { Component: DoctorDetails };
            },
            children: [
              {
                index: true,
                loader: () => redirect('all'),
              },
              {
                path: 'all',
                async lazy() {
                  let { AllConnects } = await import("./routes/dashboard/doctors/doctordetails/connections/allconnects");
                  return { Component: AllConnects };
                },
              },
              {
                path: 'active',
                async lazy() {
                  let { ActiveConnects } = await import("./routes/dashboard/doctors/doctordetails/connections/activeconnects");
                  return { Component: ActiveConnects };
                },
              },
              {
                path: 'cancelled',
                async lazy() {
                  let { CancelledConnects } = await import("./routes/dashboard/doctors/doctordetails/connections/cancelledconnects");
                  return { Component: CancelledConnects };
                },
              },
              {
                path: 'completed',
                async lazy() {
                  let { CompletedConnects } = await import("./routes/dashboard/doctors/doctordetails/connections/completedconnects");
                  return { Component: CompletedConnects };
                },
              },
            ],
          },
          {
            path: ':doctorId/earnings',
            element: <EarningIndex />,
            children: [
              {
                index: true,
                async lazy() {
                  let { Earnings } = await import("./routes/dashboard/doctors/earningdetails/earning");
                  return { Component: Earnings };
                },
              },
              {
                path: ':month',
                async lazy() {
                  let { MonthDetails } = await import("./routes/dashboard/doctors/earningdetails/monthdetails");
                  return { Component: MonthDetails };
                },
              },
            ],
          },
        ]
      },
      {
        path: 'connections',
        element: <ConnectionIndex />,
        errorElement: <p>Something went wrong</p>,
        children: [
          {
            index: true,
            async lazy() {
              let { Connections } = await import("./routes/dashboard/connections/connections");
              return { Component: Connections };
            },
          },
        ],
      },
      {
        path: 'payments',
        element: <PaymentIndex />,
        errorElement: <p>Something went wrong</p>,
        children: [
          {
            index: true,
            async lazy() {
              let { Payment } = await import("./routes/dashboard/payments/payment");
              return { Component: Payment };
            },
          },
          {
            path: ':paymentId',
            async lazy() {
              let { PayoutDetails } = await import("./routes/dashboard/payments/payoutdetails");
              return { Component: PayoutDetails };
            }
          }
        ],
      },
      {
        path: 'reports',
        async lazy() {
          let  { Reports } = await import("./routes/dashboard/reports/reports");
          return { Component: Reports };
        },
        children: [
          {
            index: true,
            loader: () => redirect('all'),
          },
          {
            path: 'all',
            async lazy() {
              let { AllMatrices } = await import("./routes/dashboard/reports/growth/allmatrices");
              return { Component: AllMatrices };
            },
          },
          {
            path: 'users',
            async lazy() {
              let { UserMatrix } = await import("./routes/dashboard/reports/growth/usermatrix");
              return { Component: UserMatrix };
            },
          },
          {
            path: 'doctors',
            async lazy() {
              let { DoctorMatrix } = await import("./routes/dashboard/reports/growth/doctormatrix");
              return { Component: DoctorMatrix };
            },
          },
        ]
      },
      {
        path: 'tickets',
        async lazy() {
          let  { Tickets } = await import("./routes/dashboard/tickets/tickets");
          return { Component: Tickets };
        },
        children: [
          {
            index: true,
            loader: () => redirect('users'),
          },
          {
            path: 'users',
            async lazy() {
              let { UsersTickets } = await import("./routes/dashboard/tickets/users/userstickets");
              return { Component: UsersTickets };
            },
          },
          {
            path: 'doctors',
            async lazy() {
              let { DoctorTickets } = await import("./routes/dashboard/tickets/doctors/doctorstickets");
              return { Component: DoctorTickets };
            },
          },
        ]
      },
      {
        path: 'broadcast',
        element: <BroadcastIndex />,
        errorElement: <p>Something went wrong</p>,
        children: [
          {
            index: true,
            async lazy() {
              let { Broadcast } = await import("./routes/dashboard/broadcast/broadcast");
              return { Component: Broadcast };
            },
          },
          {
            path: 'create-broadcast',
            async lazy() {
              let { CreateBroadCast } = await import("./routes/dashboard/broadcast/createBroadCast");
              return { Component: CreateBroadCast };
            },
            children: [
              {
                index: true,
                loader: () => redirect('users'),
              },
              {
                path: 'users',
                async lazy() {
                  let { AllUsers } = await import("./routes/dashboard/broadcast/recipients/allusers");
                  return { Component: AllUsers };
                },
              },
              {
                path: 'doctors',
                async lazy() {
                  let { AllDoctors } = await import("./routes/dashboard/broadcast/recipients/alldoctors");
                  return { Component: AllDoctors };
                },
              }
            ]
          },
          {
            path: ':broadcastId',
            async lazy() {
              let { BroadCastDetails } = await import("./routes/dashboard/broadcast/broadcastdetails/broadcastdetails");
              return { Component: BroadCastDetails };
            },
        },
        ],
      },
      {
        path: 'audit-log',
        element: <AuditIndex />,
        errorElement: <p>Something went wrong</p>,
        children: [
          {
            index: true,
            async lazy() {
              let { Audit } = await import("./routes/dashboard/audit/audit");
              return { Component: Audit };
            },
          },
        ],
      },
      {
        path: 'settings',
        async lazy() {
          let  { Settings } = await import("./routes/dashboard/settings/settings");
          return { Component: Settings };
        },
        children: [
          {
            index: true,
            loader: () => redirect('pricing'),
          },
          {
            path: 'pricing',
            async lazy() {
              let { Pricing } = await import("./routes/dashboard/settings/pricing/pricing");
              return { Component: Pricing };
            },
          },
          {
            path: 'members',
            async lazy() {
              let { Members } = await import("./routes/dashboard/settings/members/members");
              return { Component: Members };
            },
          },
          {
            path: 'roles',
            async lazy() {
              let { Roles } = await import("./routes/dashboard/settings/roles/roles");
              return { Component: Roles };
            },
          },
        ]
      },
    ]
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
    <Toaster richColors position="top-right" />
  </React.StrictMode>
);
